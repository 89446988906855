import { Box, forwardRef } from '@chakra-ui/react';
import React from 'react';

// This is a workaround for preventing bouncing while scrolling in safari. Because safari
// does not, as of today, respect `overscroll-behavior: none`, we add `overflow: hidden` to
// the body, and make *only* the content area scrollable. This prevents the bounce effect on
// the body; while there is still a bounce effect within this container, it's less
// noticeable, and we prevent it at the workbench level, which is most important.
//
// TODO: when safari supports `overscroll-behavior`, we can simplify a lot.

export const HEADER_HEIGHT = 48;

const ContentOverflow = forwardRef((props: any, ref) => {
  return <Box ref={ref} overflow='auto' height='100%' {...props} />;
});

/**
 * The main layout, as responsive, composable elements (vs HOC).
 */
export function useMainLayout() {
  return {
    Wrapper: Box,
    Content: ContentOverflow,
  };
}
