import { useBreakpointValue } from '@chakra-ui/react';
import {
  Box,
  Button,
  IconButton,
  MenuButton,
  MenuIcon,
  PlusIcon,
  useIsSmallScreen,
} from '@playful/design_system';
import React from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import { MenuSubMenu, MenuSubMenuButtonProps } from '../components/MenuSubMenu';
import { darkBlankProjectRoute, lightBlankProjectRoute } from '../components/ProjectTemplates';
import { useCleanPath } from '../hooks/useCleanPath';
import { ROUTE_NEW_PROJECT, ROUTE_TEMPLATES } from '../routes';
import { AuthLink } from './AuthFlow/AuthLink';
import { Avatar } from './Avatar';
import ProProfileBadge from './pro-profile-badge.png';
import { useNavigationLinks } from './useNavigationLinks';
import { useUserContext } from './UserContext';
import { useRouter } from '../hooks/useRouter';

const UserSettingsMenuButton = ({ isOpen, onOpen }: MenuSubMenuButtonProps) => {
  const { user, hasActiveSubscription } = useUserContext();
  return (
    <MenuButton
      as={IconButton}
      data-cy='account-button'
      aria-label='My Account'
      aria-owns={isOpen ? 'menu-appbar' : undefined}
      aria-haspopup='true'
      variant='ghost'
      onClick={onOpen}
      colorScheme='whiteAlpha'
      position='relative'
      isRound
    >
      <Avatar email={user.email} userName={user.name} />
      {hasActiveSubscription && (
        <Box position='absolute' bottom={0} right={'-2px'}>
          <img width={18} src={ProProfileBadge.src} alt='Hatch Pro' />
        </Box>
      )}
    </MenuButton>
  );
};

const MobileMenuButton = ({ onOpen }: MenuSubMenuButtonProps) => {
  return (
    <MenuButton
      as={IconButton}
      aria-label='mobile navigation menu'
      onClick={onOpen}
      colorScheme='dark'
      variant='ghost'
      icon={<MenuIcon />}
      size='sm'
      data-cy='mobile-menu-button'
    />
  );
};

export function AccountAppBarSection() {
  const { isLoggedInUser } = useUserContext();
  const isSmallScreen = useIsSmallScreen();
  const { push, query, asPath } = useRouter();
  const cleanedPath = useCleanPath(asPath);
  const { getUserProfileRoutes, getAllRoutes } = useNavigationLinks();
  const userRoutes = getUserProfileRoutes();
  const allRoutes = getAllRoutes();
  const buttonSize = useBreakpointValue({ base: 'sm', lg: 'md' }, { fallback: 'lg' });

  // TODO: make this whole header more composable for next page purposes and get rid of
  // per-page logic here
  const hideNewProject =
    (cleanedPath === ROUTE_NEW_PROJECT || cleanedPath === ROUTE_TEMPLATES) && !query.newProject;
  return (
    <>
      {isLoggedInUser ? (
        <>
          {!hideNewProject && (
            <CTAButton
              size={buttonSize}
              color='gray.900'
              leftIcon={<PlusIcon display={{ base: 'none', lg: 'block' }} />}
              pl={{ base: 2, md: 3 }}
              ml={0}
              onClick={(event) => {
                if (event.shiftKey) {
                  // If shift key is held down open a light or dark (hold alt key too) blank project.
                  push(event.altKey ? darkBlankProjectRoute : lightBlankProjectRoute);
                } else {
                  push({ query: { newProject: true } }, ROUTE_NEW_PROJECT, { shallow: true });
                }
              }}
              fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
              data-cy={'newProject'}
            >
              new project
            </CTAButton>
          )}
        </>
      ) : (
        <>
          <Button
            as={AuthLink}
            data-cy='sign-in'
            role='button'
            authType={'signIn'}
            size={buttonSize}
            fontSize={{ base: 'sm', md: 'md' }}
            colorScheme='dark'
          >
            log in
          </Button>
          <Button
            as={AuthLink}
            data-cy='sign-up'
            role='button'
            authType={'signUp'}
            size={buttonSize}
            fontSize={{ base: 'sm', md: 'md' }}
            colorScheme='yellow'
          >
            sign up
          </Button>
        </>
      )}
      {isLoggedInUser && userRoutes && !isSmallScreen && (
        <MenuSubMenu
          size='lg'
          TopMenuButton={UserSettingsMenuButton}
          {...userRoutes}
          placement='left'
        />
      )}
      {allRoutes && isSmallScreen && (
        <Box ml={2}>
          <MenuSubMenu
            TopMenuButton={MobileMenuButton}
            label={allRoutes.label}
            items={allRoutes.items}
            placement='bottom-end'
            size='sm'
          />
        </Box>
      )}
    </>
  );
}
