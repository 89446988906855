import {
  Box,
  BoxProps,
  Button,
  HStack,
  PlusIcon,
  Text,
  useIsSmallScreen,
} from '@playful/design_system';
import { customEvent } from '@playful/telemetry';
import React from 'react';

import { buildUserRoute } from '../../paths';
import { useProjectTemplates } from './ProjectTemplates';
import { useRouter } from '../../hooks/useRouter';

export const lightBlankProjectRoute = {
  pathname: buildUserRoute('play', 'portrait-light', 'edit'),
  query: { fork: true, title: 'Untitled' },
};

export const darkBlankProjectRoute = {
  pathname: buildUserRoute('play', 'portrait-dark', 'edit'),
  query: { fork: true, title: 'Untitled' },
};

export function ProjectTemplatesFooter(props: BoxProps) {
  const { hasShadow } = useProjectTemplates();
  const { push } = useRouter();
  function newBlank() {
    customEvent('templatepicker-blankpage');
    push(lightBlankProjectRoute);
  }

  const isSmScreen = useIsSmallScreen(true);

  return (
    <Box
      position='sticky'
      bg='white'
      justifyContent={'flex-start'}
      bottom={0}
      zIndex='sticky'
      pt={4}
      pb={4}
      pl={4}
      boxShadow={hasShadow ? 'lg-up' : 'none'}
      {...props}
    >
      <HStack
        alignItems='center'
        display='flex'
        px={{ base: 6, md: 6 }}
        minH={12}
        position='sticky'
      >
        <Button
          size={isSmScreen ? 'sm' : 'md'}
          variant='outline'
          color='gray.900'
          leftIcon={<PlusIcon />}
          pl={3}
          ml={0}
          onClick={newBlank}
          fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
          data-cy={'newProject'}
        >
          blank page
        </Button>
        <Text
          size={isSmScreen ? 'sm' : 'md'}
          fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
          color='gray.900'
        >
          want a fresh start?
        </Text>
      </HStack>
    </Box>
  );
}
