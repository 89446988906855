import { ButtonProps, useBreakpointValue } from '@chakra-ui/react';
import { Button, IconButton, ProBadgeIcon, useIsSmallScreen } from '@playful/design_system';
import { useUserContext } from '@playful/frontend/user/UserContext';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React from 'react';

type HatchProButtonProps = ButtonProps & {
  // how user got to the page; used for heap analytics
  origin?: string;
};

export const HatchProButton = (props: HatchProButtonProps) => {
  const isSmallScreen = useIsSmallScreen();
  const { hasActiveSubscription } = useUserContext();
  const showSmallButton = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' });
  const { origin } = props;
  const onClick = () => {
    subscriptionPaywalls.openModal({ reason: origin });
  };
  const colorScheme = props.colorScheme || 'light';
  const iconFill = colorScheme === 'dark' ? 'yellow.500' : 'purple.500';

  if (hasActiveSubscription) {
    return null;
  }

  if (isSmallScreen || showSmallButton) {
    return (
      <IconButton
        aria-label='get pro'
        size='sm'
        variant='ghost'
        colorScheme={colorScheme}
        icon={<ProBadgeIcon fill={iconFill} />}
        onClick={onClick}
        {...props}
      />
    );
  }

  return (
    <Button
      size='sm'
      colorScheme={colorScheme}
      leftIcon={<ProBadgeIcon fill={iconFill} />}
      variant='ghost'
      onClick={onClick}
      {...props}
    >
      get pro
    </Button>
  );
};
