import { Avatar as ChAvatar, AvatarProps as ChAvatarProps } from '@chakra-ui/react';
import React from 'react';

import { MD5 } from '../utils/md5';

export type AvatarProps = ChAvatarProps & {
  email?: string;
  userName: string;
};

export function Avatar({ userName, email, ...props }: AvatarProps) {
  // TODO: Add an avatar image URL to user and use that instead of Gravatar.
  const emailHash = email ? MD5(email) : undefined;

  // TODO: Tooltip w/ userName
  return (
    <ChAvatar
      height='40px'
      width='40px'
      src={emailHash ? `https://www.gravatar.com/avatar/${emailHash}?d=mp` : undefined}
      name={userName}
      {...props}
    />
  );
}
