import { useBreakpoint } from '@chakra-ui/react';
import { User, EmployeeFeatureFlags, UserFeatureFlags, UserFlags } from '@playful/api';
import { Box, MoreIcon, ProBadgeIcon, Text } from '@playful/design_system';
import type { RouteItem } from '@playful/frontend/components/MenuSubMenu';
import { humanizePropertyName } from '@playful/runtime';
import RouterDefault from 'next/router';
import React, { useCallback } from 'react';

import { buildUserRoute } from '../paths';
import {
  ROUTE_ABOUT,
  ROUTE_BLOG,
  ROUTE_CAREERS,
  ROUTE_CONTACT,
  ROUTE_FAQS,
  ROUTE_FORUM,
  ROUTE_GALLERY,
  ROUTE_HOME,
  ROUTE_MY_ACCOUNT,
  ROUTE_PRICING,
  ROUTE_SUPPORT,
  ROUTE_TEMPLATES,
  ROUTE_TUTORIALS,
  ROUTE_WHATS_NEW,
} from '../routes';
import { useUserContext } from './UserContext';

export const UserLabel = () => {
  const { user, hasActiveSubscription } = useUserContext();

  return (
    <Box py={2} display='flex' justifyContent='space-between' alignItems='center' w='100%'>
      <Box minW='0' mr={2}>
        <Box>
          <Text
            fontSize='md'
            fontWeight='medium'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
          >
            @{user.name}
          </Text>
        </Box>
        <Box>
          <Text fontSize='xs' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
            {user.email}
          </Text>
        </Box>
      </Box>
      {hasActiveSubscription && (
        <Box textAlign='center'>
          <Box>
            <ProBadgeIcon />
          </Box>
          <Box>
            <Text fontSize='2xs' color='purple.600' fontWeight='bold'>
              PRO
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const MY_HATCH_ROUTES = (userName: string): RouteItem => {
  return {
    label: 'my hatch',
    items: [
      {
        label: 'my projects',
        path: ROUTE_HOME,
      },
      {
        label: 'my community profile',
        path: buildUserRoute(userName),
      },
    ],
  };
};

const HELP_MENU_ITEMS: RouteItem = {
  label: 'help',
  items: [
    {
      label: 'forum',
      url: ROUTE_FORUM,
    },
    {
      label: 'tutorials',
      url: ROUTE_TUTORIALS,
    },
    {
      label: 'faqs',
      url: ROUTE_FAQS,
    },
    {
      label: 'customer support',
      url: ROUTE_SUPPORT,
    },
  ],
};

const ABOUT_MENU_ITEMS: RouteItem = {
  label: 'about',
  items: [
    {
      label: 'what’s new',
      url: ROUTE_WHATS_NEW,
    },
    {
      label: 'our team',
      url: ROUTE_ABOUT,
    },
    {
      label: 'pricing',
      url: ROUTE_PRICING,
    },
    {
      label: 'careers',
      url: ROUTE_CAREERS,
    },
    {
      label: 'contact',
      url: ROUTE_CONTACT,
    },
  ],
};

const INSPIRATION_ROUTES: RouteItem = {
  label: 'inspiration',
  items: [
    { path: ROUTE_GALLERY, label: 'community gallery' },
    { url: ROUTE_BLOG, label: 'blog' },
    { path: ROUTE_TEMPLATES, label: 'templates' },
  ],
};

const USERINFO: RouteItem = {
  label: <UserLabel />,
  menuItemProps: {
    _hover: {},
    _active: {},
  },
};

const DIVIDER: RouteItem = {
  type: 'divider',
};

const GetSignOut = (onSignOutClick: () => void): RouteItem => {
  return {
    label: 'sign out',
    onClick: onSignOutClick,
  };
};

const navigationRoutes = ({
  user,
  isLoggedInUser,
  breakpoint,
}: {
  user: User;
  isLoggedInUser: boolean;
  breakpoint: string;
}): RouteItem[] => {
  const routes = [];

  if (breakpoint === 'md') {
    routes.push(INSPIRATION_ROUTES, {
      label: <MoreIcon />,
      items: [HELP_MENU_ITEMS, ABOUT_MENU_ITEMS],
      withArrow: false,
    });
  } else if (!['base', 'sm', 'xs'].includes(breakpoint)) {
    routes.push(INSPIRATION_ROUTES, HELP_MENU_ITEMS, ABOUT_MENU_ITEMS);
  }

  if (isLoggedInUser) {
    routes.unshift(MY_HATCH_ROUTES(user.name));
  }

  return routes;
};

const getUserRoutes = ({
  user,
  onSignOutClick,
  hasFlag,
  toggleUserFlag,
}: {
  user: User;
  onSignOutClick: () => void;
  hasFlag: (flag: keyof UserFlags) => boolean;
  toggleUserFlag: (flag: keyof UserFlags) => void;
}): RouteItem[] => {
  const isEmployee = user.userType === 'employee';
  const flags = isEmployee ? { ...UserFeatureFlags, ...EmployeeFeatureFlags } : UserFeatureFlags;
  const items: RouteItem[] = [
    {
      label: 'my account',
      path: ROUTE_MY_ACCOUNT,
    },
    {
      label: 'tool settings',
      items: Object.keys(flags).map((feature) => ({
        label: `${hasFlag(feature as any) ? 'Disable' : 'Enable'} ${humanizePropertyName(feature)}`,
        onClick: () => toggleUserFlag(feature as keyof UserFlags),
      })),
    },
  ];

  // Easy way to switch between CMS modes
  if (isEmployee) {
    if (RouterDefault.isPreview) {
      items.push({
        label: 'disable draft mode',
        path: '/api/disable-draft',
      });
    } else {
      items.push({
        label: 'enable draft mode',
        path: '/api/draft',
      });
    }
  }
  return items;
};

export const useNavigationLinks = () => {
  const { user, isLoggedInUser, signOut, hasFlag, toggleUserFlag } = useUserContext();
  const breakpoint = useBreakpoint({ fallback: 'lg' });

  const getNavigationRoutes = useCallback(() => {
    return navigationRoutes({
      user: user,
      isLoggedInUser,
      breakpoint,
    });
  }, [user, isLoggedInUser, breakpoint]);

  const getUserProfileRoutes = useCallback(() => {
    const userRoutes = getUserRoutes({
      user: user,
      onSignOutClick: signOut,
      hasFlag,
      toggleUserFlag,
    });

    return {
      label: '',
      items: [USERINFO, DIVIDER, ...userRoutes, DIVIDER, GetSignOut(signOut)],
    };
  }, [hasFlag, signOut, toggleUserFlag, user]);

  const getAllRoutes = useCallback(
    (includeMyHatch = false) => {
      const navigationRoutes = [INSPIRATION_ROUTES, HELP_MENU_ITEMS, ABOUT_MENU_ITEMS];

      if (!isLoggedInUser) {
        return {
          label: '',
          items: navigationRoutes,
        };
      }

      const userRoutes = getUserRoutes({
        user: user,
        onSignOutClick: signOut,
        hasFlag,
        toggleUserFlag,
      });

      const allRoutes = {
        label: '',
        items: [
          USERINFO,
          DIVIDER,
          ...userRoutes,
          DIVIDER,
          ...navigationRoutes,
          DIVIDER,
          GetSignOut(signOut),
        ],
      };
      return allRoutes;
    },
    [isLoggedInUser, user, signOut, hasFlag, toggleUserFlag],
  );

  return {
    getUserProfileRoutes,
    getNavigationRoutes,
    getAllRoutes,
  };
};
