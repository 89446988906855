import { useTargetScrolledBehind } from '@playful/design_system';
import { createGenericContext } from '@playful/frontend/utils/createGenericContext';
import React from 'react';

type ContainerPadding = {
  top: number;
  left: number;
};

type TemplateCtx = {
  hasShadow: boolean;
  shadowRef: React.MutableRefObject<null>;
  containerPadding: ContainerPadding;
};

const [hook, Provider] = createGenericContext<TemplateCtx>();

export const useProjectTemplates = hook;

export function ProjectTemplates({
  children,
  containerPadding,
}: {
  children: React.ReactNode;
  containerPadding: ContainerPadding;
}) {
  // useTargetScrolledBehind's ref needs to be scoped to the modal content
  const { isBehind: hasShadow, scrollTargetRef } = useTargetScrolledBehind();

  return (
    <Provider value={{ containerPadding, shadowRef: scrollTargetRef, hasShadow }}>
      {children}
    </Provider>
  );
}
