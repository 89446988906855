import { useEffect, useRef, useState } from 'react';
// TODO: more gooder name
/**
 * Is the scrollTargetRef is overflowing its parent `isBehind` will return true else false
 */
export const useTargetScrolledBehind = () => {
    const [intersecting, setIntersecting] = useState(true);
    const scrollTargetRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries?.[0];
            if (entry) {
                setIntersecting(entry.isIntersecting);
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });
        if (scrollTargetRef.current) {
            observer.observe(scrollTargetRef.current);
        }
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollTargetRef.current]);
    return {
        isBehind: !intersecting,
        scrollTargetRef,
    };
};
