import { PlacementWithLogical, Portal, useBreakpoint } from '@chakra-ui/react';
import {
  Box,
  Button,
  ChevronRightIcon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  RouteLink,
} from '@playful/design_system';
import { customEvent, formatEventString } from '@playful/telemetry';
import React, { PropsWithChildren, createContext, useContext, useRef } from 'react';

import useDisclosure from '../utils/useDisclosure';

interface MenuProviderProps {
  placement: PlacementWithLogical;
}
interface MenuContextProps {
  placement: PlacementWithLogical;
}

export type RouteItem = {
  label?: React.ReactNode;
  path?: string;
  url?: string;
  items?: RouteItem[];
  type?: string;
  onClick?: () => void;
  withArrow?: boolean;
  menuItemProps?: MenuItemProps;
};

export interface MenuSubMenuButtonProps {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  label?: React.ReactNode;
  withArrow?: boolean;
}

type MenuSubMenuProps = {
  TopMenuButton: any;
  placement?: PlacementWithLogical;
  size?: string;
} & RouteItem;

type MenuSubMenuItemProps = {
  closeParent: () => void;
} & RouteItem;

const MenuContext = createContext<MenuContextProps>(undefined!);
const useMenuContext = () => useContext(MenuContext);
function MenuProvider({ children, placement }: PropsWithChildren<MenuProviderProps>) {
  return <MenuContext.Provider value={{ placement }}>{children}</MenuContext.Provider>;
}

export const MenuSubMenu = ({
  label,
  items,
  TopMenuButton,
  placement = 'right',
  withArrow = true,
  size = 'auto',
}: MenuSubMenuProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const breakpoint = useBreakpoint({ fallback: 'lg' });
  if (!items || !breakpoint) return null;
  return (
    <MenuProvider placement={placement}>
      <Menu size={size} autoSelect isOpen={isOpen} onClose={onClose}>
        <TopMenuButton
          label={label}
          withArrow={withArrow}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
        />
        <MenuSubMenuList
          items={items}
          closeParent={onClose}
          parentLabel={label?.toString() ?? ''}
        />
      </Menu>
    </MenuProvider>
  );
};

const MenuSubMenuList = ({
  items,
  closeParent,
  parentLabel,
}: {
  items: RouteItem[];
  closeParent: () => void;
  parentLabel: string;
}) => {
  return (
    <Portal>
      <MenuList boxShadow='lg' borderColor='gray.100'>
        {items.map(({ path, label, url, items, onClick, type, menuItemProps = {} }, idx) => {
          const key = typeof label === 'string' ? label : url || path || idx;
          if (type === 'divider') return <MenuDivider key={key} />;
          const clickHandler = () => {
            // For analytics tracking by specific menu in header like `click-inspiration`or `click-help`
            customEvent(`${formatEventString(parentLabel)}-click`, {
              link: formatEventString(label?.toString() ?? ''),
            });
            onClick?.();
          };
          return (
            <MenuSubMenuItem
              key={key}
              path={path}
              label={label}
              url={url}
              items={items}
              onClick={clickHandler}
              closeParent={() => closeParent?.()}
              menuItemProps={menuItemProps}
            />
          );
        })}
      </MenuList>
    </Portal>
  );
};

const MenuSubMenuItem = ({
  path,
  label,
  url,
  items,
  onClick,
  closeParent,
  menuItemProps,
}: MenuSubMenuItemProps) => {
  const itemRef = useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const hasChildren = !!items && items.length;
  const isLink = !!path || !!url;
  const interactive = isLink || hasChildren;

  let itemType: any = Box;

  if (!!path) {
    itemType = RouteLink;
  } else if (!!url) {
    itemType = Link;
  }

  return (
    <MenuItem
      as={itemType}
      key={url || path}
      href={url || path}
      color={'gray.700'}
      fontSize='md'
      fontWeight='medium'
      cursor={interactive ? 'pointer' : 'auto'}
      userSelect={interactive ? 'none' : 'text'}
      closeOnSelect={false}
      ref={itemRef}
      p={0}
      onClick={() => {
        onClick?.();
        if (isLink) {
          closeParent();
        }
      }}
      minHeight='40px'
      _hover={{
        bgColor: 'gray.50',
        color: 'gray.700',
        textDecoration: 'none',
      }}
      _active={{
        bgColor: 'gray.100',
      }}
      _focus={{
        bgColor: 'gray.100',
        color: 'gray.700',
      }}
      {...menuItemProps}
    >
      {hasChildren ? (
        <MenuSubMenuSubMenu
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          parentEl={itemRef.current}
          label={label}
          items={items}
          closeParent={() => {
            closeParent?.();
            onClose();
          }}
        />
      ) : (
        <Box px={4} fontWeight='medium' w='100%'>
          {label}
        </Box>
      )}
    </MenuItem>
  );
};

type MenuSubMenuSubMenuProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  closeParent: () => void;
  parentEl: HTMLElement | undefined;
} & RouteItem;

// TOIDO: Dont underline onclicks
const MenuSubMenuSubMenu = ({
  label,
  items,
  parentEl,
  isOpen,
  onOpen,
  onClose,
  closeParent,
}: MenuSubMenuSubMenuProps) => {
  const { placement } = useMenuContext();
  if (!items) return null;
  return (
    <Menu
      autoSelect
      computePositionOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      boundary={parentEl}
      placement={placement}
      size='auto'
    >
      <MenuButton
        as={Button}
        variant='solid'
        color='gray.900'
        w='100%'
        h='100%'
        bgColor='transparent'
        textAlign='left'
        p={0}
        _hover={{
          bgColor: 'transparent',
        }}
        _focus={{
          bgColor: 'transparent',
        }}
        _active={{
          bgColor: 'transparent',
        }}
        onClick={() => (isOpen ? onClose() : onOpen())}
        rightIcon={<ChevronRightIcon />}
        sx={{
          svg: { w: 4, h: 4 },
        }}
        px={4}
        fontWeight='medium'
      >
        {label}
      </MenuButton>
      <MenuSubMenuList
        items={items}
        closeParent={() => {
          onClose();
          closeParent();
        }}
        parentLabel={label?.toString() ?? ''}
      />
    </Menu>
  );
};
