import { Box, BoxProps, Heading } from '@playful/design_system';
import React from 'react';

import { useProjectTemplates } from './ProjectTemplates';

export function ProjectTemplatesContent({
  children,
  title,
  ...boxProps
}: BoxProps & {
  title?: string;
}) {
  const { shadowRef } = useProjectTemplates();

  return (
    <Box {...boxProps}>
      {title && (
        <Heading as='h1' size='lg' noOfLines={1}>
          {title}
        </Heading>
      )}
      <Box display='flex' flexDir='column' gap={10} py={4}>
        {children}
      </Box>
      <div style={{ marginTop: 0 }} ref={shadowRef} />
    </Box>
  );
}
